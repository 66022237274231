
window.onload = function() {
  if( 
      // feature detection based approach to determine we're on a modern browser
      // https://responsivenews.co.uk/post/18948466399/cutting-the-mustard
      !( 'querySelector' in document
      && 'localStorage' in window
      && 'addEventListener' in window )

      ||
      
      // we also don't support IE10 or older, despite passing the above check
      window.navigator.userAgent.indexOf('MSIE ') > 0
  ) {
    window.location.href = 'unsupported_browser.html';
  }
}

